

export function tax(
  lookupTable: any,
  married_or_civil_partnership: boolean,
  over_70: boolean,
  spouse_a_salary: number,
  spouse_a_benefits_in_kind: number,
  spouse_a_work_expenses: number,
  spouse_a_self_employment_trading_income: number,
  spouse_a_pension_income: number,
  spouse_a_pension_expenses: number,
  spouse_a_other_income: number,
  spouse_b_salary: number,
  spouse_b_benefits_in_kind: number,
  spouse_b_work_expenses: number,
  spouse_b_self_employment_trading_income: number,
  spouse_b_pension_income: number,
  spouse_b_pension_expenses: number,
  spouse_b_other_income: number,
  dependent_children: number,
  child_care_paid: number,
  mortgage_balance: number,
  mortgage_interest: number,
  independent: boolean,
  adjustedChildAllowance: number,
  adjustedAdditionalAllowance: number,
  allowAdditionalChildAllowance: boolean,
  fromDate: string,
  toDate: string
) {

  // Calculations

  let lca_marginal = lookupTable.lca_marginal.annual
  let apa_marginal = lookupTable.apa_marginal.annual
  let single_exemtion = lookupTable.single_exemtion.annual
  let married_exemtion = lookupTable.married_exemtion.annual
  let second_earners_allowance = lookupTable.second_earners_allowance.annual
  let enhanced_cctr_max_per_child = lookupTable.enhanced_cctr_max_per_child.annual
  let bik_deduction = lookupTable.bik_deduction.annual

  if (adjustedChildAllowance >= 0) {
    lca_marginal = adjustedChildAllowance
  }
  if (adjustedAdditionalAllowance >= 0) {
    apa_marginal = adjustedAdditionalAllowance
  }

  // if fromDate is not January 1st, the we need to calculate the number of days from the fromDate to the toDate
  // and then calculate the number of days between fromDate and toDate
  let fromDateObj = new Date(fromDate)
  let toDateObj = new Date(toDate)

  // Get total days in the year, for example 365 or 366
  const daysInYear = fromDateObj.getFullYear() % 4 === 0 ? 366 : 365
  let aportionedDays = daysInYear

  if ((fromDateObj.getMonth() !== 0 || fromDateObj.getDate() !== 1) || (toDateObj.getMonth() !== 11 || toDateObj.getDate() !== 31)) {
    let diff = toDateObj.getTime() - fromDateObj.getTime()
    aportionedDays = Math.ceil(diff / (1000 * 3600 * 24))

    single_exemtion = Math.min(Math.ceil((single_exemtion / daysInYear) * aportionedDays), single_exemtion);
    married_exemtion = Math.min(Math.ceil((married_exemtion / daysInYear) * aportionedDays), married_exemtion);
    lca_marginal = Math.min(Math.ceil((lca_marginal / daysInYear) * aportionedDays), lca_marginal);
    apa_marginal = Math.min(Math.ceil((apa_marginal / daysInYear) * aportionedDays), apa_marginal);
    enhanced_cctr_max_per_child = Math.min(Math.ceil((enhanced_cctr_max_per_child / daysInYear) * aportionedDays), enhanced_cctr_max_per_child);
    bik_deduction = Math.min(Math.ceil((bik_deduction / daysInYear) * aportionedDays), bik_deduction);
  }


  // SPOUSE A

  // - Net Salary
  const spouse_a_net_salary = () => {
    const result = spouse_a_salary - spouse_a_work_expenses;
    return (result > 0) ? result : 0;
  }
  // - Benefit in kind with max deduction
  const spouse_a_benefits_in_kind_with_max_deduction = () => {
    return Math.min(spouse_a_benefits_in_kind, bik_deduction);
  }
  // Net BIK Income
  const spouse_a_net_bik_income = () => {
    return spouse_a_benefits_in_kind - spouse_a_benefits_in_kind_with_max_deduction();
  }
  // - Your earned income
  const spouse_a_earned_income = () => {
    return spouse_a_net_salary() + spouse_a_self_employment_trading_income + spouse_a_pension_income + spouse_a_net_bik_income();
  }
  // - Your gross earned income
  const spouse_a_gross_earned_income = () => {
    return spouse_a_salary + spouse_a_self_employment_trading_income + spouse_a_pension_income + spouse_a_benefits_in_kind
  }
  // - Allowable pension amount
  const spouse_a_allowable_pension_amount = () => {
    return (spouse_a_pension_expenses < lookupTable.retirement_annuity_relief_max.annual) ? spouse_a_pension_expenses : lookupTable.retirement_annuity_relief_max.annual;
  }
  // Spouse A Excess Pension Restriction
  const spouse_a_pension_restriction = () => {
    let income = spouse_a_net_salary() + spouse_a_self_employment_trading_income + spouse_a_pension_income + spouse_a_other_income;
    let excess = (income - lookupTable.rar_max_income.annual <= 0) ? 0 : income - lookupTable.rar_max_income.annual;
    let pension = spouse_a_allowable_pension_amount();
    let net_income = spouse_a_net_salary() + spouse_a_self_employment_trading_income + spouse_a_net_bik_income();
    let sum = Math.min(pension, net_income - excess, lookupTable.retirement_annuity_relief_max.annual - excess);
    return (sum > 0) ? sum : 0;
  }
  // - Taxable earning
  const spouse_a_taxable_earnings = () => {
    const result = spouse_a_earned_income() - spouse_a_pension_restriction();
    return (result > 0) ? result : 0;
  }
  // - Your Total income
  const spouse_a_total_income = () => {
    return spouse_a_taxable_earnings() + spouse_a_other_income
  }

  // SPOUSE B

  // - Spouses net salary
  const spouse_b_net_salary = () => {
    const result = spouse_b_salary - spouse_b_work_expenses;
    return (result > 0) ? result : 0;
  }
  // - Benefit in kind with max deduction
  const spouse_b_benefits_in_kind_with_max_deduction = () => {
    return Math.min(spouse_b_benefits_in_kind, bik_deduction);
  }
  // Net BIK Income
  const spouse_b_net_bik_income = () => {
    return spouse_b_benefits_in_kind - spouse_b_benefits_in_kind_with_max_deduction();
  }
  // - Spouse's earned income
  const spouse_b_earned_income = () => {
    return spouse_b_net_salary() + spouse_b_self_employment_trading_income + spouse_b_pension_income + spouse_b_net_bik_income();
  }
  // - Spouse's gross earned income
  const spouse_b_gross_earned_income = () => {
    return spouse_b_salary + spouse_b_self_employment_trading_income + spouse_b_pension_income + spouse_b_benefits_in_kind
  }
  // - Spouse's Allowable pension amount
  const spouse_b_allowable_pension_amount = () => {
    return (spouse_b_pension_expenses < lookupTable.retirement_annuity_relief_max.annual) ? spouse_b_pension_expenses : lookupTable.retirement_annuity_relief_max.annual;
  }
  // Spouse B Excess Pension Restriction
  const spouse_b_pension_restriction = () => {
    let income = spouse_b_net_salary() + spouse_b_self_employment_trading_income + spouse_b_pension_income + spouse_b_other_income;
    let excess = (income - lookupTable.rar_max_income.annual <= 0) ? 0 : income - lookupTable.rar_max_income.annual;
    let pension = spouse_b_allowable_pension_amount();
    let net_income = spouse_b_net_salary() + spouse_b_self_employment_trading_income + spouse_b_net_bik_income();
    let sum = Math.min(pension, net_income - excess, lookupTable.retirement_annuity_relief_max.annual - excess);
    return (sum > 0) ? sum : 0;
  }
  // - Spouse taxable earnings
  const spouse_b_taxable_earnings = () => {
    const result = spouse_b_earned_income() - spouse_b_pension_restriction();
    return (result > 0) ? result : 0;
  }
  // - Your spouse's total income
  const spouse_b_total_income = () => {
    return spouse_b_taxable_earnings() + spouse_b_other_income
  }

  // COMBINED CALCULATIONS

  // - Assessable combined total income
  const assessable_combined_total_income = () => {
    return (married_or_civil_partnership === false) ? spouse_a_total_income() : spouse_a_total_income() + spouse_b_total_income();
  }
  // - Combined Other Income
  const combined_other_income = () => {
    return (married_or_civil_partnership === false) ? spouse_a_other_income : spouse_a_other_income + spouse_b_other_income;
  }

  // CHILD CALCULATIONS

  // - Child Allowance
  const child_allowance = () => {
    let sum = dependent_children * lca_marginal
    return (independent === true) ? sum / 2 : sum;
  }
  // - Calculated child care max
  const child_care_max = () => {
    let child_care_paid_temp = child_care_paid;
    let enhanced_cctr_max_per_child_temp = enhanced_cctr_max_per_child;

    if (independent === true) {
      enhanced_cctr_max_per_child_temp = enhanced_cctr_max_per_child / 2;
      child_care_paid_temp = child_care_paid / 2;
    }

    let sum = (child_care_paid_temp > enhanced_cctr_max_per_child_temp * dependent_children)
      ? enhanced_cctr_max_per_child_temp * dependent_children
      : child_care_paid_temp;

    let lowest;

    if (married_or_civil_partnership === true) {
      let lowest_earner = Math.min(
        spouse_a_net_salary() + spouse_a_net_bik_income() + spouse_a_self_employment_trading_income - spouse_a_pension_restriction(),
        spouse_b_net_salary() + spouse_b_net_bik_income() + spouse_b_self_employment_trading_income - spouse_b_pension_restriction()
      );
      let result = lowest_earner - second_earners_allowance;
      lowest = Math.min(result, sum);
    } else {
      let result = spouse_a_net_salary() + spouse_a_net_bik_income() + spouse_a_self_employment_trading_income - spouse_a_pension_restriction() - lookupTable.apa_marginal.annual;
      lowest = Math.min(result, sum);
    }

    return (lowest <= 0) ? 0 : lowest;
  }

  // - Additional Allowance
  const additional_allowance = () => {
    return (married_or_civil_partnership === true) ? 0 : (allowAdditionalChildAllowance === false) ? 0 : apa_marginal;
  }

  // - Allowable additional allowance
  const allowable_additional_allowance = () => {
    return (dependent_children > 0) ? (independent === true) ? 0 : additional_allowance() : 0;
  }

  // AGE & MORTGAGE CALCULATIONS

  // - Age Allowance
  const age_allowance = () => {
    return (married_or_civil_partnership === true && over_70 === true) ? lookupTable.married_age_exemtion.annual : 0;
  }
  // - Capital restriction (if any)
  const capital_restriction = () => {
    const res = (lookupTable.mortgage_capital_cap.annual / mortgage_balance > 1) ? mortgage_interest : (lookupTable.mortgage_capital_cap.annual / mortgage_balance) * mortgage_interest;
    return (isNaN(res)) ? 0 : res;
  }
  // - Allowable mortgage interest
  // =IF(AI2>BF13,BF13/52,AI2/52)
  const allowable_mortgage_interest = () => {
    let res = (capital_restriction() > lookupTable.mortgage_interest_tax_relief_max_marginal.annual) ? lookupTable.mortgage_interest_tax_relief_max_marginal.annual : capital_restriction();

    return Math.round((independent === true) ? res / 2 : res);
  }

  // MARGINAL & STANDARD CALCULATIONS

  // - Taxable Marginal income
  const taxable_marginal_income = () => {
    return assessable_combined_total_income() - (child_allowance() + child_care_max() + allowable_mortgage_interest() + allowable_additional_allowance() + age_allowance());
  }
  // - Standard calculation
  const standard_calculation = () => {
    return assessable_combined_total_income() * lookupTable.standard_tax_rate.annual
  }
  // - Marginal exemption
  const marginal_exemption = () => {
    return (married_or_civil_partnership === false) ? single_exemtion : married_exemtion;
  }
  // - Second earner's relief
  const second_earners_relief = () => {
    let lowest_earner = Math.min(spouse_a_taxable_earnings(), spouse_b_taxable_earnings());
    let result = Math.ceil((((lowest_earner > second_earners_allowance) ? second_earners_allowance : lowest_earner) / daysInYear) * aportionedDays);
    return Math.min(result, second_earners_allowance);
  }
  // - Allowable second earner's relief
  const allowable_second_earners_relief = () => {
    return (married_or_civil_partnership === false) ? 0 : second_earners_relief();
  }
  // - Marginal calculation
  const marginal_calculation = () => {
    let sum = (taxable_marginal_income() - marginal_exemption() - allowable_second_earners_relief()) * lookupTable.marginal_rate.annual;
    return (sum < 0) ? 0 : sum;
  }
  // - Spouse A Max Standard LTC
  const spouse_a_max_standard_ltc = () => {
    let sum = spouse_a_total_income() * lookupTable.ltc_standard.annual
    return (sum < lookupTable.max_ltc.annual) ? sum : lookupTable.max_ltc.annual;
  }
  // - Spouse B Max Standard LTC
  const spouse_b_max_standard_ltc = () => {
    if (married_or_civil_partnership === false) {
      return 0
    } else {
      let sum = spouse_b_total_income() * lookupTable.ltc_standard.annual
      return (sum < lookupTable.max_ltc.annual) ? sum : lookupTable.max_ltc.annual;
    }
  }
  // - Combined Max Stardard LTC
  const combined_max_standard_ltc = () => {
    return spouse_a_max_standard_ltc() + spouse_b_max_standard_ltc();
  }
  // - Spouse A Max Marginal LTC
  const spouse_a_max_marginal_ltc = () => {
    let sum = spouse_a_total_income() * lookupTable.ltc_marginal.annual
    return (sum < lookupTable.max_ltc.annual) ? sum : lookupTable.max_ltc.annual;
  }
  // - Spouse B Max Marginal LTC
  const spouse_b_max_marginal_ltc = () => {
    if (married_or_civil_partnership === false) {
      return 0
    } else {
      let sum = spouse_b_total_income() * lookupTable.ltc_marginal.annual
      return (sum < lookupTable.max_ltc.annual) ? sum : lookupTable.max_ltc.annual;
    }
  }
  // - Combined Max Marginal LTC
  const combined_max_marginal_ltc = () => {
    return spouse_a_max_marginal_ltc() + spouse_b_max_marginal_ltc();
  }
  // - Marginal LTC
  const marginal_rate_ltc = () => {
    let sum = (taxable_marginal_income() - marginal_exemption() - allowable_second_earners_relief()) * lookupTable.ltc_marginal.annual;
    return (sum < 0) ? 0 : sum;
  }
  // - Standard rate LTC
  const standard_rate_ltc = () => {
    return assessable_combined_total_income() * lookupTable.ltc_standard.annual
  }
  // - LTC
  const marginal_ltc = () => {
    return Math.min(combined_max_marginal_ltc(), marginal_rate_ltc())
  }
  // - LTC
  const standard_ltc = () => {
    return Math.min(combined_max_standard_ltc(), standard_rate_ltc())
  }
  // - Tax and LTC
  // const tax_and_ltc = () => {
  //   let sum = tax() + ltc()
  //   return (sum < 0) ? 0 : sum;
  // }
  // Marginal Relief
  const marginal_relief = () => {
    const sum = (standard_calculation() + standard_rate_ltc()) - (marginal_calculation() + marginal_ltc())
    return (sum < 0) ? 0 : sum;
  }
  // Total Income Gross
  const gross_total_income = () => {
    return (married_or_civil_partnership === false) ? spouse_a_gross_earned_income() + spouse_a_other_income : spouse_a_gross_earned_income() + spouse_a_other_income + spouse_b_gross_earned_income() + spouse_b_other_income;
  }
  // Total Earned Income
  const total_earned_income = () => {
    return (married_or_civil_partnership === false) ? spouse_a_gross_earned_income() : spouse_a_gross_earned_income() + spouse_b_gross_earned_income();
  }
  // Total Unearned Income
  const total_unearned_income = () => {
    return (married_or_civil_partnership === false) ? spouse_a_other_income : spouse_a_other_income + spouse_b_other_income;
  }
  // Standard Tax
  const standard_tax_ltc = (): { [key: string]: any } => {
    return {
      total: standard_calculation() + standard_ltc(),
      details: [
        {
          name: "Tax",
          value: standard_calculation()
        },
        {
          name: "Long-term care",
          value: standard_ltc()
        }
      ]
    }
  }
  // Standard Tax
  const marginal_tax_ltc = (): { [key: string]: any } => {
    return {
      total: marginal_calculation() + marginal_ltc(),
      details: [
        {
          name: "Tax",
          value: marginal_calculation()
        },
        {
          name: "Long-term care",
          value: marginal_ltc()
        }
      ]
    }
  }
  // - Tax
  const tax_ltc = () => {
    return (marginal_tax_ltc().total < standard_tax_ltc().total) ? marginal_tax_ltc().total : standard_tax_ltc().total;
  }
  // Spouse A Allowable Work Expenses
  const spouse_a_allowable_work_expenses = () => {
    return (spouse_a_salary > 0) ? Math.min(spouse_a_salary, spouse_a_work_expenses) : 0;
  }
  // Spouse B Allowable Work Expenses
  const spouse_b_allowable_work_expenses = () => {
    return (spouse_b_salary > 0) ? Math.min(spouse_b_salary, spouse_b_work_expenses) : 0;
  }
  // Spouse A Total Work and Pension and BIK Exemptions
  const spouse_a_total_work_pension_exemptions = () => {
    let expenses = spouse_a_allowable_work_expenses() + spouse_a_pension_restriction() + spouse_a_benefits_in_kind_with_max_deduction();
    let income = spouse_a_salary + spouse_a_self_employment_trading_income;
    return (income < expenses) ? income : expenses;
  }
  // Spouse B Total Work and Pension and BIK Exemptions
  const spouse_b_total_work_pension_exemptions = () => {
    let expenses = spouse_b_allowable_work_expenses() + spouse_b_pension_restriction() + spouse_b_benefits_in_kind_with_max_deduction();
    let income = spouse_b_salary + spouse_b_self_employment_trading_income;
    return (income < expenses) ? income : expenses;
  }
  // Combined Total Work and Pension and BIK Exemptions
  const combine_total_work_pension_exemptions = () => {
    return spouse_a_total_work_pension_exemptions() + spouse_b_total_work_pension_exemptions();
  }
  // - Combined ITIS rate
  const combined_itis_rate = () => {
    let sum = tax_ltc() / assessable_combined_total_income()
    return ((sum > lookupTable.max_itis_rate.annual) ? lookupTable.max_itis_rate.annual : sum) * 100;
  }
  // - Your income tax instalment rate
  const your_income_tax_instalment_rate = () => {
    if (assessable_combined_total_income() === 0) {
      return 0;
    }
    return combined_itis_rate();
  }
  // - Weekly overpayment or underpayment from salary instalments
  const over_or_under_payment = () => {
    let sum = (married_or_civil_partnership === false) ?
      tax_ltc().total - spouse_a_salary * (your_income_tax_instalment_rate() / 100) :
      tax_ltc().total - (spouse_a_salary + spouse_b_salary) * (your_income_tax_instalment_rate() / 100);
    return (isNaN(sum)) ? 0 : sum;
  }

  // Oraganise Data for Graphic Details
  const salary_income = () => {
    return {
      total: total_earned_income() + total_unearned_income(),
      earned: total_earned_income(),
      unearned: total_unearned_income(),
      details: [
        {
          name: "Total Income",
          value: total_earned_income() + total_unearned_income(),
        },
        {
          name: "Salary",
          value: spouse_a_salary + spouse_b_salary
        },
        {
          name: "Benefits in Kind",
          value: spouse_a_benefits_in_kind + spouse_b_benefits_in_kind
        },
        {
          name: "Self-employed",
          value: spouse_a_self_employment_trading_income + spouse_b_self_employment_trading_income
        },
        {
          name: "Pension",
          value: spouse_a_pension_income + spouse_b_pension_income
        },
        {
          name: "Other",
          value: spouse_a_other_income + spouse_b_other_income
        }
      ]
    }
  }

  // Total Tax Threshold Details
  const total_tax_threshold = () => {
    let sum = (married_or_civil_partnership === false) ?
      single_exemtion :
      married_exemtion;

    return {
      total: (sum + age_allowance() < gross_total_income()) ? sum + age_allowance() : gross_total_income(),
      details: [
        {
          name: (married_or_civil_partnership === false) ? "Single allowance" : "Married allowance",
          value: (sum < gross_total_income()) ? sum : gross_total_income()
        },
        // {
        //   name: "Age Allowance",
        //   value: age_allowance()
        // }
      ]
    }
  }

  // Standard Deduction Details
  const work_pension_deductions = () => {
    return {
      total: combine_total_work_pension_exemptions(),
      bik: spouse_a_benefits_in_kind_with_max_deduction() + spouse_b_benefits_in_kind_with_max_deduction(),
      work: spouse_a_allowable_work_expenses() + spouse_b_allowable_work_expenses(),
      pension: spouse_a_pension_restriction() + spouse_b_pension_restriction(),
      details: [
        {
          name: "Benefits in Kind",
          value: spouse_a_benefits_in_kind_with_max_deduction() + spouse_b_benefits_in_kind_with_max_deduction()
        },
        {
          name: "Work Expenses",
          value: spouse_a_allowable_work_expenses() + spouse_b_allowable_work_expenses()
        },
        {
          name: "Pension Contributions",
          value: spouse_a_pension_restriction() + spouse_b_pension_restriction()
        }
      ]
    }
  }

  // Standard Taxable Income Details
  const standard_taxable_income = () => {
    const taxable_income = total_earned_income() + total_unearned_income() - combine_total_work_pension_exemptions()
    return {
      total: taxable_income,
      details: [
        {
          name: "Taxable Income",
          value: taxable_income
        }
      ]
    }
  }

  // Second Earners Details
  const second_earners = () => {
    return {
      total: allowable_second_earners_relief(),
      details: [
        {
          name: "Second Earner's Allowance",
          value: allowable_second_earners_relief()
        }
      ]
    }
  }

  // Other allowances
  const other_allowances = () => {
    const total = child_allowance() + child_care_max() + allowable_mortgage_interest() + allowable_additional_allowance()
    return {
      total,
      child_allowances: child_allowance() + child_care_max() + allowable_additional_allowance(),
      mortgage_interest: allowable_mortgage_interest(),
      details: [
        {
          name: "Total",
          value: total
        },
        {
          name: "Child Allowance",
          value: child_allowance()
        },
        {
          name: "Child Care Max",
          value: child_care_max()
        },
        {
          name: "Allowable Mortgage Interest",
          value: allowable_mortgage_interest()
        },
        {
          name: "Additional Allowance",
          value: allowable_additional_allowance()
        }
      ]
    }
  }

  // Marginal Taxable Income Details
  const marginal_taxable_income = () => {
    let total_deductions =
      total_tax_threshold().total +
      other_allowances().total +
      work_pension_deductions().total +
      second_earners().total;
    const taxable_income = salary_income().total > total_deductions
      ? salary_income().total - total_deductions
      : 0;

    return {
      total: taxable_income,
      details: [
        {
          name: "Taxable Income",
          value: taxable_income
        }
      ]
    }
  }

  const calculateTax = (): { [key: string]: any } => {
    return {
      salary_income,
      tax: tax_ltc(),
      standard_tax_ltc,
      marginal_tax_ltc,
      marginal_relief: marginal_relief(),
      total_tax_threshold,
      other_allowances,
      work_pension_deductions,
      second_earners,
      standard_taxable_income,
      marginal_taxable_income,
      your_income_tax_instalment_rate,
      marginal_tax_percentage: ((marginal_tax_ltc().total / salary_income().total) * 100) || 0,
      standard_tax_percentage: ((standard_tax_ltc().total / salary_income().total) * 100) || 0
    }
  }
  return {
    calculateTax
  }
}