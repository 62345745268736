
import axios from "axios";
import {
  IonButton,
  IonList,
  IonItem,
  IonRange,
  IonInput,
  IonCol,
  IonRow,
  IonGrid,
  IonIcon,
  IonCheckbox,
  popoverController
} from "@ionic/vue";
import { helpCircleOutline } from "ionicons/icons";
import { tax } from "@/functions/functions";
import { config } from "@/functions/config";
import IntroText from "@/components/IntroText.vue";
import TaxWidget from "@/components/TaxWidget.vue";
import GraphicKey from "@/components/GraphicKey.vue";
import ContextualHelp from "@/components/ContextualHelp.vue";
import ResultsTable from "@/components/ResultsTable.vue";

enum YEAR {
  THIS = 2024,
  NEXT = 2025
}

export default {
  name: "Home",
  components: {
    IonButton,
    IonList,
    IonItem,
    IonRange,
    IonInput,
    IonCol,
    IonRow,
    IonGrid,
    IonIcon,
    IonCheckbox,
    IntroText,
    TaxWidget,
    GraphicKey,
    ResultsTable,
  },
  data() {
    return {
      YEAR: YEAR,
      year: YEAR.NEXT,
      fromDate: YEAR.NEXT + "-01-01",
      toDate: YEAR.NEXT + "-12-31",
      show_benefits_in_kind_spouse_a: false,
      show_self_employment_income_spouse_a: false,
      show_pension_income_spouse_a: false,
      show_other_income_spouse_a: false,
      show_benefits_in_kind_spouse_b: false,
      show_self_employment_income_spouse_b: false,
      show_pension_income_spouse_b: false,
      show_other_income_spouse_b: false,
      toggleGraphicTable: false,
      showIntroText: true,
      config,
      helpCircleOutline,
      lookupTable: {},
      adjustedChildAllowance: -1,
      computedChildAllowanceIsActive: false,
      adjustedAdditionalAllowance: -1,
      computedAdditionalAllowanceIsActive: false,
      tempValChildAllowance: 0,
      tempValAdditionalAllowance: 0,
      toggleReduceChildAllowance: false,
      interval: "annual",
      haveChildren: false,
      haveMortgage: false,
      married_or_civil_partnership: false,
      independently_taxed: false,
      over_70: false,
      spouse_a_show_other_income: false,
      spouse_b_show_other_income: false,
      spouse_a_salary: 0,
      display_spouse_a_salary_is_active: false,
      spouse_a_salary_options: {
        annual: {
          max: 100000,
          step: 1000,
        },
        monthly: {
          max: 10000,
          step: 100,
        },
        weekly: {
          max: 2000,
          step: 50,
        },
      },
      spouse_a_benefits_in_kind: 0,
      display_spouse_a_benefits_in_kind_is_active: false,
      spouse_a_benefits_in_kind_options: {
        annual: {
          max: 5000,
          step: 100,
        },
        monthly: {
          max: 400,
          step: 10,
        },
        weekly: {
          max: 100,
          step: 5,
        },
      },
      spouse_a_self_employment_trading_income: 0,
      display_spouse_a_self_employment_trading_income_is_active: false,
      spouse_a_self_employment_trading_income_options: {
        annual: {
          max: 100000,
          step: 1000,
        },
        monthly: {
          max: 10000,
          step: 100,
        },
        weekly: {
          max: 2000,
          step: 50,
        },
      },
      spouse_a_pension_income: 0,
      display_spouse_a_pension_income_is_active: false,
      spouse_a_pension_income_options: {
        annual: {
          max: 50000,
          step: 1000,
        },
        monthly: {
          max: 5000,
          step: 100,
        },
        weekly: {
          max: 1000,
          step: 10,
        },
      },
      spouse_a_other_income: 0,
      display_spouse_a_other_income_is_active: false,
      spouse_a_other_income_options: {
        annual: {
          max: 50000,
          step: 1000,
        },
        monthly: {
          max: 5000,
          step: 100,
        },
        weekly: {
          max: 1000,
          step: 10,
        },
      },
      spouse_a_work_expenses: 0,
      display_spouse_a_work_expenses_is_active: false,
      spouse_a_work_expenses_options: {
        annual: {
          max: 1000,
          step: 100,
        },
        monthly: {
          max: 100,
          step: 10,
        },
        weekly: {
          max: 20,
          step: 5,
        },
      },
      spouse_a_pension_expenses: 0,
      display_spouse_a_pension_expenses_is_active: false,
      spouse_a_pension_expenses_options: {
        annual: {
          max: 50000,
          step: 1000,
        },
        monthly: {
          max: 5000,
          step: 100,
        },
        weekly: {
          max: 1000,
          step: 10,
        },
      },
      spouse_b_salary: 0,
      display_spouse_b_salary_is_active: false,
      spouse_b_salary_options: {
        annual: {
          max: 100000,
          step: 1000,
        },
        monthly: {
          max: 10000,
          step: 100,
        },
        weekly: {
          max: 2000,
          step: 50,
        },
      },
      spouse_b_benefits_in_kind: 0,
      display_spouse_b_benefits_in_kind_is_active: false,
      spouse_b_benefits_in_kind_options: {
        annual: {
          max: 5000,
          step: 100,
        },
        monthly: {
          max: 400,
          step: 10,
        },
        weekly: {
          max: 100,
          step: 5,
        },
      },
      spouse_b_self_employment_trading_income: 0,
      display_spouse_b_self_employment_trading_income_is_active: false,
      spouse_b_self_employment_trading_income_options: {
        annual: {
          max: 100000,
          step: 1000,
        },
        monthly: {
          max: 10000,
          step: 100,
        },
        weekly: {
          max: 2000,
          step: 50,
        },
      },
      spouse_b_pension_income: 0,
      display_spouse_b_pension_income_is_active: false,
      spouse_b_pension_income_options: {
        annual: {
          max: 50000,
          step: 1000,
        },
        monthly: {
          max: 5000,
          step: 100,
        },
        weekly: {
          max: 1000,
          step: 50,
        },
      },
      spouse_b_other_income: 0,
      display_spouse_b_other_income_is_active: false,
      spouse_b_other_income_options: {
        annual: {
          max: 50000,
          step: 1000,
        },
        monthly: {
          max: 5000,
          step: 100,
        },
        weekly: {
          max: 1000,
          step: 50,
        },
      },
      spouse_b_work_expenses: 0,
      display_spouse_b_work_expenses_is_active: false,
      spouse_b_work_expenses_options: {
        annual: {
          max: 1000,
          step: 50,
        },
        monthly: {
          max: 500,
          step: 10,
        },
        weekly: {
          max: 20,
          step: 5,
        },
      },
      spouse_b_pension_expenses: 0,
      display_spouse_b_pension_expenses_is_active: false,
      spouse_b_pension_expenses_options: {
        annual: {
          max: 50000,
          step: 1000,
        },
        monthly: {
          max: 5000,
          step: 100,
        },
        weekly: {
          max: 1000,
          step: 50,
        },
      },
      dependent_children: 0,
      display_dependent_children_is_active: false,
      child_care_paid: 0,
      display_child_care_paid_is_active: false,
      child_care_paid_options: {
        annual: {
          max: 30000,
          step: 1000,
        },
        monthly: {
          max: 3000,
          step: 100,
        },
        weekly: {
          max: 1000,
          step: 50,
        },
      },
      mortgage_balance: 0,
      display_mortgage_balance_is_active: false,
      mortgage_interest: 0,
      display_mortgage_interest_is_active: false,
      salary_income: { total: 0 },
      tax: 0,
      standard_tax: { total: 0, details: [] },
      marginal_tax: { total: 0, details: [] },
      marginal_relief: 0,
      // over_or_under_payment: 0,
      // non_salary_income: 0,
      // combined_itis_rate: 0,
      your_income_tax_instalment_rate: 0,
      marginal_tax_percentage: 0,
      standard_tax_percentage: 0,
      // total_earned_income: 0,
      // total_unearned_income: 0,
      total_tax_threshold: { total: 0 },
      other_allowances: {
        total: 0,
        child_allowances: 0,
        mortgage_interest: 0,
        details: [],
      },
      work_pension_deductions: { total: 0, work: 0, pension: 0 },
      second_earners: { total: 0 },
      standard_taxable_income: { total: 0 },
      marginal_taxable_income: { total: 0 },
      salary_income_spouse_a: { total: 0 },
      tax_spouse_a: 0,
      standard_tax_spouse_a: { total: 0, details: [] },
      marginal_tax_spouse_a: { total: 0, details: [] },
      marginal_relief_spouse_a: 0,
      over_or_under_payment_spouse_a: 0,
      non_salary_income_spouse_a: 0,
      combined_itis_rate_spouse_a: 0,
      your_income_tax_instalment_rate_spouse_a: 0,
      marginal_tax_percentage_spouse_a: 0,
      standard_tax_percentage_spouse_a: 0,
      total_earned_income_spouse_a: 0,
      total_unearned_income_spouse_a: 0,
      total_tax_threshold_spouse_a: { total: 0 },
      other_allowances_spouse_a: {
        total: 0,
        child_allowances: 0,
        mortgage_interest: 0,
        details: [],
      },
      work_pension_deductions_spouse_a: { total: 0, work: 0, pension: 0 },
      second_earners_spouse_a: { total: 0 },
      standard_taxable_income_spouse_a: { total: 0 },
      marginal_taxable_income_spouse_a: { total: 0 },
      salary_income_spouse_b: { total: 0 },
      tax_spouse_b: 0,
      standard_tax_spouse_b: { total: 0, details: [] },
      marginal_tax_spouse_b: { total: 0, details: [] },
      marginal_relief_spouse_b: 0,
      over_or_under_payment_spouse_b: 0,
      non_salary_income_spouse_b: 0,
      combined_itis_rate_spouse_b: 0,
      your_income_tax_instalment_rate_spouse_b: 0,
      marginal_tax_percentage_spouse_b: 0,
      standard_tax_percentage_spouse_b: 0,
      total_earned_income_spouse_b: 0,
      total_unearned_income_spouse_b: 0,
      total_tax_threshold_spouse_b: { total: 0 },
      other_allowances_spouse_b: {
        total: 0,
        child_allowances: 0,
        mortgage_interest: 0,
        details: [],
      },
      work_pension_deductions_spouse_b: { total: 0, work: 0, pension: 0 },
      standard_taxable_income_spouse_b: { total: 0 },
      marginal_taxable_income_spouse_b: { total: 0 },
      second_earners_spouse_b: { total: 0 },
      showIndepenentWidget: false,
    };
  },
  computed: {
    maxAdditionalAllowance(): number {
      if (Object.keys(this.lookupTable).length > 0) {
        return (this.lookupTable as any).apa_marginal.annual;
      } else {
        return 0;
      }
    },
    maxChildAllowance(): number {
      if (Object.keys(this.lookupTable).length > 0) {
        return (
          this.dependent_children *
          (this.lookupTable as any).lca_marginal.annual
        );
      } else {
        return 0;
      }
    },
    computedChildAllowance: {
      get: function (): any {
        if (Object.keys(this.lookupTable).length > 0) {
          let lca_marginal = (this.lookupTable as any).lca_marginal.annual;

          if (
            this.computedChildAllowanceIsActive &&
            this.tempValChildAllowance > lca_marginal * this.dependent_children
          ) {
            return this.tempValChildAllowance;
          }

          if (this.adjustedChildAllowance >= 0) {
            lca_marginal = this.adjustedChildAllowance;
          }
          return this.dependent_children * lca_marginal;
        } else {
          return 0;
        }
      },
      set: function (modifiedValue) {
        this.tempValChildAllowance = parseInt(modifiedValue);
        if (this.maxChildAllowance < parseInt(modifiedValue)) {
          modifiedValue = this.maxChildAllowance;
        }
        if (modifiedValue === "") {
          modifiedValue = 0;
        }

        this.adjustedChildAllowance =
          parseInt(modifiedValue) / this.dependent_children;

        this.getCalculatedTax();
        this.getCalculatedTaxSpouseA();
        this.getCalculatedTaxSpouseB();
      },
    },
    computedAdditionalAllowance: {
      get: function (): any {
        if (Object.keys(this.lookupTable).length > 0) {
          let apa_marginal = (this.lookupTable as any).apa_marginal.annual;

          if (
            this.computedAdditionalAllowanceIsActive &&
            this.tempValAdditionalAllowance > apa_marginal
          ) {
            return this.tempValAdditionalAllowance;
          }

          if (this.adjustedAdditionalAllowance >= 0) {
            apa_marginal = this.adjustedAdditionalAllowance;
          }
          return parseInt(apa_marginal);
        } else {
          return 0;
        }
      },
      set: function (modifiedValue) {
        this.tempValAdditionalAllowance = parseInt(modifiedValue);
        if (this.maxAdditionalAllowance < parseInt(modifiedValue)) {
          modifiedValue = this.maxAdditionalAllowance;
        }
        if (modifiedValue === "") {
          modifiedValue = 0;
        }

        this.adjustedAdditionalAllowance = parseInt(modifiedValue);

        this.getCalculatedTax();
        this.getCalculatedTaxSpouseA();
        this.getCalculatedTaxSpouseB();
      },
    },
    independentDifference(): { value: number; message: string} {
      let res: any = {};

      let ind = this.tax_spouse_a + this.tax_spouse_b;
      let mar = this.tax;

      res.value = ind;

      if (+ind.toFixed(2) > +mar.toFixed(2)) {
        res.message =
          "Based of these figures you would currently pay more tax if you are independently taxed. You may wish to wait until the compensatory allowance is available. This will be introduced when Independent Taxation becomes mandatory.";
      } else {
        res.message = "";
      }
      return res;
    },
    display_interval_postfix(): string {
      const postfix: { [key: string]: string } = {
        annual: "",
        monthly: "/PM",
        weekly: "/PW",
      };
      return postfix[this.interval];
    },
    display_single_married(): string {
      return this.married_or_civil_partnership ? "Married" : "Single";
    },
    display_spouse_a_salary: {
      get: function (): string {
        if (this.display_spouse_a_salary_is_active) {
          return "";
        } else {
          return (
            "£" +
            this.spouse_a_salary
              .toFixed(0)
              .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") +
            this.display_interval_postfix
          );
        }
      },
      set: function (modifiedValue) {
        // Recalculate value after ignoring "$" and "," in user input
        let newValue = parseFloat(modifiedValue.replace(/[^\d.]/g, ""));
        // Ensure that it is not NaN
        if (isNaN(newValue)) {
          newValue = 0;
        }
        // $emit the event so that parent component gets it
        this.$emit("input", newValue);
        this.spouse_a_salary = newValue;
      },
    },
    display_spouse_a_benefits_in_kind: {
      get: function (): string {
        if (this.display_spouse_a_benefits_in_kind_is_active) {
          return "";
        } else {
          return (
            "£" +
            this.spouse_a_benefits_in_kind
              .toFixed(0)
              .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") +
            this.display_interval_postfix
          );
        }
      },
      set: function (modifiedValue) {
        let newValue = parseFloat(modifiedValue.replace(/[^\d.]/g, ""));
        if (isNaN(newValue)) {
          newValue = 0;
        }
        this.$emit("input", newValue);
        this.spouse_a_benefits_in_kind = newValue;
      },
    },
    display_spouse_a_self_employment_trading_income: {
      get: function (): string {
        if (this.display_spouse_a_self_employment_trading_income_is_active) {
          return "";
        } else {
          return (
            "£" +
            this.spouse_a_self_employment_trading_income
              .toFixed(0)
              .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") +
            this.display_interval_postfix
          );
        }
      },
      set: function (modifiedValue) {
        let newValue = parseFloat(modifiedValue.replace(/[^\d.]/g, ""));
        if (isNaN(newValue)) {
          newValue = 0;
        }
        this.$emit("input", newValue);
        this.spouse_a_self_employment_trading_income = newValue;
      },
    },
    display_spouse_a_pension_income: {
      get: function (): string {
        if (this.display_spouse_a_pension_income_is_active) {
          return "";
        } else {
          return (
            "£" +
            this.spouse_a_pension_income
              .toFixed(0)
              .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") +
            this.display_interval_postfix
          );
        }
      },
      set: function (modifiedValue) {
        let newValue = parseFloat(modifiedValue.replace(/[^\d.]/g, ""));
        if (isNaN(newValue)) {
          newValue = 0;
        }
        this.$emit("input", newValue);
        this.spouse_a_pension_income = newValue;
      },
    },
    display_spouse_a_other_income: {
      get: function (): string {
        if (this.display_spouse_a_other_income_is_active) {
          return "";
        } else {
          return (
            "£" +
            this.spouse_a_other_income
              .toFixed(0)
              .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") +
            this.display_interval_postfix
          );
        }
      },
      set: function (modifiedValue) {
        let newValue = parseFloat(modifiedValue.replace(/[^\d.]/g, ""));
        if (isNaN(newValue)) {
          newValue = 0;
        }
        this.$emit("input", newValue);
        this.spouse_a_other_income = newValue;
      },
    },
    display_spouse_a_work_expenses: {
      get: function (): string {
        if (this.display_spouse_a_work_expenses_is_active) {
          return "";
        } else {
          return (
            "£" +
            this.spouse_a_work_expenses
              .toFixed(0)
              .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") +
            this.display_interval_postfix
          );
        }
      },
      set: function (modifiedValue) {
        let newValue = parseFloat(modifiedValue.replace(/[^\d.]/g, ""));
        if (isNaN(newValue)) {
          newValue = 0;
        }
        this.$emit("input", newValue);
        this.spouse_a_work_expenses = newValue;
      },
    },
    display_spouse_a_pension_expenses: {
      get: function (): string {
        if (this.display_spouse_a_pension_expenses_is_active) {
          return "";
        } else {
          return (
            "£" +
            this.spouse_a_pension_expenses
              .toFixed(0)
              .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") +
            this.display_interval_postfix
          );
        }
      },
      set: function (modifiedValue) {
        let newValue = parseFloat(modifiedValue.replace(/[^\d.]/g, ""));
        if (isNaN(newValue)) {
          newValue = 0;
        }
        this.$emit("input", newValue);
        this.spouse_a_pension_expenses = newValue;
      },
    },
    display_spouse_b_salary: {
      get: function (): string {
        if (this.display_spouse_b_salary_is_active) {
          return "";
        } else {
          return (
            "£" +
            this.spouse_b_salary
              .toFixed(0)
              .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") +
            this.display_interval_postfix
          );
        }
      },
      set: function (modifiedValue) {
        let newValue = parseFloat(modifiedValue.replace(/[^\d.]/g, ""));
        if (isNaN(newValue)) {
          newValue = 0;
        }
        this.$emit("input", newValue);
        this.spouse_b_salary = newValue;
      },
    },
    display_spouse_b_benefits_in_kind: {
      get: function (): string {
        if (this.display_spouse_b_benefits_in_kind_is_active) {
          return "";
        } else {
          return (
            "£" +
            this.spouse_b_benefits_in_kind
              .toFixed(0)
              .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") +
            this.display_interval_postfix
          );
        }
      },
      set: function (modifiedValue) {
        let newValue = parseFloat(modifiedValue.replace(/[^\d.]/g, ""));
        if (isNaN(newValue)) {
          newValue = 0;
        }
        this.$emit("input", newValue);
        this.spouse_b_benefits_in_kind = newValue;
      },
    },
    display_spouse_b_self_employment_trading_income: {
      get: function (): string {
        if (this.display_spouse_b_self_employment_trading_income_is_active) {
          return "";
        } else {
          return (
            "£" +
            this.spouse_b_self_employment_trading_income
              .toFixed(0)
              .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") +
            this.display_interval_postfix
          );
        }
      },
      set: function (modifiedValue) {
        let newValue = parseFloat(modifiedValue.replace(/[^\d.]/g, ""));
        if (isNaN(newValue)) {
          newValue = 0;
        }
        this.$emit("input", newValue);
        this.spouse_b_self_employment_trading_income = newValue;
      },
    },
    display_spouse_b_pension_income: {
      get: function (): string {
        if (this.display_spouse_b_pension_income_is_active) {
          return "";
        } else {
          return (
            "£" +
            this.spouse_b_pension_income
              .toFixed(0)
              .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") +
            this.display_interval_postfix
          );
        }
      },
      set: function (modifiedValue) {
        let newValue = parseFloat(modifiedValue.replace(/[^\d.]/g, ""));
        if (isNaN(newValue)) {
          newValue = 0;
        }
        this.$emit("input", newValue);
        this.spouse_b_pension_income = newValue;
      },
    },
    display_spouse_b_other_income: {
      get: function (): string {
        if (this.display_spouse_b_other_income_is_active) {
          return "";
        } else {
          return (
            "£" +
            this.spouse_b_other_income
              .toFixed(0)
              .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") +
            this.display_interval_postfix
          );
        }
      },
      set: function (modifiedValue) {
        let newValue = parseFloat(modifiedValue.replace(/[^\d.]/g, ""));
        if (isNaN(newValue)) {
          newValue = 0;
        }
        this.$emit("input", newValue);
        this.spouse_b_other_income = newValue;
      },
    },
    display_spouse_b_work_expenses: {
      get: function (): string {
        if (this.display_spouse_b_work_expenses_is_active) {
          return "";
        } else {
          return (
            "£" +
            this.spouse_b_work_expenses
              .toFixed(0)
              .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") +
            this.display_interval_postfix
          );
        }
      },
      set: function (modifiedValue) {
        let newValue = parseFloat(modifiedValue.replace(/[^\d.]/g, ""));
        if (isNaN(newValue)) {
          newValue = 0;
        }
        this.$emit("input", newValue);
        this.spouse_b_work_expenses = newValue;
      },
    },
    display_spouse_b_pension_expenses: {
      get: function (): string {
        if (this.display_spouse_b_pension_expenses_is_active) {
          return "";
        } else {
          return (
            "£" +
            this.spouse_b_pension_expenses
              .toFixed(0)
              .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") +
            this.display_interval_postfix
          );
        }
      },
      set: function (modifiedValue) {
        let newValue = parseFloat(modifiedValue.replace(/[^\d.]/g, ""));
        if (isNaN(newValue)) {
          newValue = 0;
        }
        this.$emit("input", newValue);
        this.spouse_b_pension_expenses = newValue;
      },
    },
    display_dependent_children: {
      get: function (): string {
        if (this.display_dependent_children_is_active) {
          return "";
        } else {
          return this.dependent_children
            .toFixed(0)
            .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
        }
      },
      set: function (modifiedValue) {
        let newValue = parseFloat(modifiedValue.replace(/[^\d.]/g, ""));
        if (isNaN(newValue)) {
          newValue = 0;
        }
        this.$emit("input", newValue);
        this.dependent_children = newValue;
      },
    },
    display_child_care_paid: {
      get: function (): string {
        if (this.display_child_care_paid_is_active) {
          return "";
        } else {
          return (
            "£" +
            this.child_care_paid
              .toFixed(0)
              .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") +
            this.display_interval_postfix
          );
        }
      },
      set: function (modifiedValue) {
        let newValue = parseFloat(modifiedValue.replace(/[^\d.]/g, ""));
        if (isNaN(newValue)) {
          newValue = 0;
        }
        this.$emit("input", newValue);
        this.child_care_paid = newValue;
      },
    },
    display_mortgage_balance: {
      get: function (): string {
        if (this.display_mortgage_balance_is_active) {
          return "";
        } else {
          return (
            "£" +
            this.mortgage_balance
              .toFixed(0)
              .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
          );
        }
      },
      set: function (modifiedValue) {
        let newValue = parseFloat(modifiedValue.replace(/[^\d.]/g, ""));
        if (isNaN(newValue)) {
          newValue = 0;
        }
        this.$emit("input", newValue);
        this.mortgage_balance = newValue;
      },
    },
    display_mortgage_interest: {
      get: function (): string {
        if (this.display_mortgage_interest_is_active) {
          return "";
        } else {
          return (
            "£" +
            this.mortgage_interest
              .toFixed(0)
              .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") +
            "/PA"
          );
        }
      },
      set: function (modifiedValue) {
        let newValue = parseFloat(modifiedValue.replace(/[^\d.]/g, ""));
        if (isNaN(newValue)) {
          newValue = 0;
        }
        this.$emit("input", newValue);
        this.mortgage_interest = newValue;
      },
    },
    fromDateMin: function (): string {
      // Get the current year from this.year
      // Set the date to January 1st of that year
      return this.year + "-01-01";
    },
    fromDateMax: function (): string {
      // Get the current year from this.year
      // Set the date to end of year minus 1 day
      return this.year + "-12-30";
    },
    toDateMin: function (): string {
      // Get the current year from this.year
      // Set the date to the begining of that year plus 1 day
      return this.year + "-01-02";
    },
    toDateMax: function (): string {
      // Get the current year from this.year
      // Set the date to end of year
      return this.year + "-12-31";
    },
    isAnnualOnly: function (): boolean {
      // Return true if fromDate and toDate are not January 1st and December 31st
      return !(this.fromDate === this.fromDateMin && this.toDate === this.toDateMax);
    }
  },
  methods: {
    resetDates() {
      this.fromDate = this.fromDateMin;
      this.toDate = this.toDateMax;
    },
    setIntervalToAnnual() {
      this.interval = "annual";
    },
    handleReduceChildAllowance(e: any) {
      this.computedChildAllowance = -1;
      this.computedAdditionalAllowance = -1;
    },
    handleKeydown(e: any) {
      e.stopPropagation();
      if (e.keyCode == 13) {
        e.preventDefault();
        e.target.blur();
      }
    },
    handleIntervalFigures(interval: string): void {
      this.spouse_a_salary = 0;
      this.spouse_a_benefits_in_kind = 0;
      this.spouse_a_work_expenses = 0;
      this.spouse_a_self_employment_trading_income = 0;
      this.spouse_a_pension_income = 0;
      this.spouse_a_pension_expenses = 0;
      this.spouse_a_other_income = 0;
      this.spouse_b_salary = 0;
      this.spouse_b_benefits_in_kind = 0;
      this.spouse_b_work_expenses = 0;
      this.spouse_b_self_employment_trading_income = 0;
      this.spouse_b_pension_income = 0;
      this.spouse_b_pension_expenses = 0;
      this.spouse_b_other_income = 0;
      this.dependent_children = 0;
      this.child_care_paid = 0;
      this.mortgage_balance = 0;
      this.mortgage_interest = 0;
      this.interval = interval;
      this.getCalculatedTax();
    },
    handleMarried(value: boolean): void {
      this.spouse_b_salary = 0;
      this.spouse_b_benefits_in_kind = 0;
      this.spouse_b_self_employment_trading_income = 0;
      this.spouse_b_pension_income = 0;
      this.spouse_b_other_income = 0;
      this.spouse_b_work_expenses = 0;
      this.spouse_b_pension_expenses = 0;
      this.married_or_civil_partnership = value;
      this.showIndepenentWidget = false;
      this.independently_taxed = true;
      this.getCalculatedTax();
    },
    handleIndependentlyTaxed(value: boolean): void {
      this.showIndepenentWidget = false;
      this.independently_taxed = value;
      this.getCalculatedTax();
      this.getCalculatedTaxSpouseA();
    },
    handleOver70(value: boolean): void {
      this.over_70 = value;
      this.getCalculatedTax();
    },
    handleHaveChildren(value: boolean): void {
      this.dependent_children = 0;
      this.child_care_paid = 0;
      this.haveChildren = value;
      this.getCalculatedTax();
    },
    handleHaveMortgage(value: boolean): void {
      this.mortgage_balance = 0;
      this.mortgage_interest = 0;
      this.haveMortgage = value;
      this.getCalculatedTax();
    },
    async getLookUpTable(): Promise<void> {
      // Get CSV File Data
      let res = await axios.get(
          "https://snap.ams3.cdn.digitaloceanspaces.com/tax/tax_lookup_table_2024.csv?v=1"
      );
      if (this.year === YEAR.NEXT) {
        res = await axios.get(
          "https://snap.ams3.cdn.digitaloceanspaces.com/tax/tax_lookup_table_2025.csv?v=1"
        );
      }
      // Get CSV and split the lines into an array
      let csvSplit = res.data.split("\r\n");

      // Setup lookup object to be filled with the CSV rows
      let lookUp: { [key: string]: any } = {};
      // Get the headers of the CSV to use as keys
      let valueKeys = csvSplit[0].split(",");
      // Loop rows and fill lookup object
      csvSplit.forEach(function (item: any, index: any) {
        // Skip header row of CSV
        if (index === 0) return;
        let cells = item.split(",");
        lookUp[cells[0]] = {
          [valueKeys[1]]: +cells[1],
          [valueKeys[2]]: +cells[2],
          [valueKeys[3]]: +cells[3],
        };
      });
      this.lookupTable = lookUp;

      this.getCalculatedTax();
      this.getCalculatedTaxSpouseA();
      this.getCalculatedTaxSpouseB();
    },
    setYear(year: number): void {
      this.year = year;
      // Update only the year this.fromDate and this.toDate
      this.fromDate = this.fromDate.replace(/\d{4}/, year.toString());
      this.toDate = this.toDate.replace(/\d{4}/, year.toString());
      this.getLookUpTable();
    },
    numberWithCommas(x: any): string {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    clearFormat(modifiedValue: string): void {
      let newValue = parseFloat(modifiedValue.replace(/[^\d.]/g, ""));
      // Ensure that it is not NaN
      if (isNaN(newValue)) {
        newValue = 0;
      }
      this.spouse_a_salary = newValue;
    },
    getCalculatedTax(): void {
      const interval_calc: { [key: string]: number } = {
        weekly: 52,
        monthly: 12,
        annual: 1,
      };
      const { calculateTax } = tax(
        this.lookupTable,
        this.married_or_civil_partnership,
        this.over_70,
        this.spouse_a_salary * interval_calc[this.interval],
        this.spouse_a_benefits_in_kind * interval_calc[this.interval],
        this.spouse_a_work_expenses * interval_calc[this.interval],
        this.spouse_a_self_employment_trading_income *
          interval_calc[this.interval],
        this.spouse_a_pension_income * interval_calc[this.interval],
        this.spouse_a_pension_expenses * interval_calc[this.interval],
        this.spouse_a_other_income * interval_calc[this.interval],
        this.spouse_b_salary * interval_calc[this.interval],
        this.spouse_b_benefits_in_kind * interval_calc[this.interval],
        this.spouse_b_work_expenses * interval_calc[this.interval],
        this.spouse_b_self_employment_trading_income *
          interval_calc[this.interval],
        this.spouse_b_pension_income * interval_calc[this.interval],
        this.spouse_b_pension_expenses * interval_calc[this.interval],
        this.spouse_b_other_income * interval_calc[this.interval],
        this.dependent_children,
        this.child_care_paid * interval_calc[this.interval],
        this.mortgage_balance,
        this.mortgage_interest,
        false,
        this.adjustedChildAllowance,
        this.adjustedAdditionalAllowance,
        true,
        this.fromDate,
        this.toDate
      );
      let res = calculateTax();

      this.salary_income = res.salary_income();
      this.tax = res.tax;
      this.standard_tax = res.standard_tax_ltc();
      this.marginal_tax = res.marginal_tax_ltc();
      this.marginal_relief = res.marginal_relief;
      this.total_tax_threshold = res.total_tax_threshold();
      this.other_allowances = res.other_allowances();
      this.work_pension_deductions = res.work_pension_deductions();
      this.second_earners = res.second_earners();
      this.standard_taxable_income = res.standard_taxable_income();
      this.marginal_taxable_income = res.marginal_taxable_income();
      this.your_income_tax_instalment_rate = res.your_income_tax_instalment_rate();
      this.marginal_tax_percentage = res.marginal_tax_percentage;
      this.standard_tax_percentage = res.standard_tax_percentage;
    },
    getCalculatedTaxSpouseA(): void {
      const interval_calc: { [key: string]: number } = {
        weekly: 52,
        monthly: 12,
        annual: 1,
      };
      const { calculateTax } = tax(
        this.lookupTable,
        // MarriedOrCivilPartnership
        // If Married and independently taxed or showing independent split, give single allowance
        (this.married_or_civil_partnership === true &&
          this.independently_taxed === true) ||
          this.showIndepenentWidget
          ? false
          : true,
        this.over_70,
        this.spouse_a_salary * interval_calc[this.interval],
        this.spouse_a_benefits_in_kind * interval_calc[this.interval],
        this.spouse_a_work_expenses * interval_calc[this.interval],
        this.spouse_a_self_employment_trading_income *
          interval_calc[this.interval],
        this.spouse_a_pension_income * interval_calc[this.interval],
        this.spouse_a_pension_expenses * interval_calc[this.interval],
        this.spouse_a_other_income * interval_calc[this.interval],
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        this.dependent_children,
        this.child_care_paid * interval_calc[this.interval],
        this.mortgage_balance,
        this.mortgage_interest,
        // This is to tell the calculation to calc as independent or not
        this.married_or_civil_partnership === true &&
          this.independently_taxed === true
          ? false
          : true,
        this.adjustedChildAllowance,
        this.adjustedAdditionalAllowance,
        // This is to allow additional allowance or not
        this.married_or_civil_partnership === true &&
          this.independently_taxed === true
          ? false
          : true,
        this.fromDate,
        this.toDate
      );
      let res = calculateTax();

      this.salary_income_spouse_a = res.salary_income();
      this.tax_spouse_a = res.tax;
      this.standard_tax_spouse_a = res.standard_tax_ltc();
      this.marginal_tax_spouse_a = res.marginal_tax_ltc();
      this.marginal_relief_spouse_a = res.marginal_relief;
      this.total_tax_threshold_spouse_a = res.total_tax_threshold();
      this.other_allowances_spouse_a = res.other_allowances();
      this.work_pension_deductions_spouse_a = res.work_pension_deductions();
      this.standard_taxable_income_spouse_a = res.standard_taxable_income();
      this.marginal_taxable_income_spouse_a = res.marginal_taxable_income();
      this.your_income_tax_instalment_rate_spouse_a = res.your_income_tax_instalment_rate();
      this.marginal_tax_percentage_spouse_a = res.marginal_tax_percentage;
      this.standard_tax_percentage_spouse_a = res.standard_tax_percentage;
    },
    getCalculatedTaxSpouseB(): void {
      const interval_calc: { [key: string]: number } = {
        weekly: 52,
        monthly: 12,
        annual: 1,
      };
      const { calculateTax } = tax(
        this.lookupTable,
        false, // MarriedOrCivilPartnership
        this.over_70,
        this.spouse_b_salary * interval_calc[this.interval],
        this.spouse_b_benefits_in_kind * interval_calc[this.interval],
        this.spouse_b_work_expenses * interval_calc[this.interval],
        this.spouse_b_self_employment_trading_income *
          interval_calc[this.interval],
        this.spouse_b_pension_income * interval_calc[this.interval],
        this.spouse_b_pension_expenses * interval_calc[this.interval],
        this.spouse_b_other_income * interval_calc[this.interval],
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        this.dependent_children,
        this.child_care_paid * interval_calc[this.interval],
        this.mortgage_balance,
        this.mortgage_interest,
        true,
        this.adjustedChildAllowance,
        this.adjustedAdditionalAllowance,
        true,
        this.fromDate,
        this.toDate
      );
      let res = calculateTax();

      this.salary_income_spouse_b = res.salary_income();
      this.tax_spouse_b = res.tax;
      this.standard_tax_spouse_b = res.standard_tax_ltc();
      this.marginal_tax_spouse_b = res.marginal_tax_ltc();
      this.marginal_relief_spouse_b = res.marginal_relief;
      this.total_tax_threshold_spouse_b = res.total_tax_threshold();
      this.other_allowances_spouse_b = res.other_allowances();
      this.work_pension_deductions_spouse_b = res.work_pension_deductions();
      this.standard_taxable_income_spouse_b = res.standard_taxable_income();
      this.marginal_taxable_income_spouse_b = res.marginal_taxable_income();
      this.your_income_tax_instalment_rate_spouse_b = res.your_income_tax_instalment_rate();
      this.marginal_tax_percentage_spouse_b = res.marginal_tax_percentage;
      this.standard_tax_percentage_spouse_b = res.standard_tax_percentage;
    },
    calculateAll() {
      this.getCalculatedTax();
      this.getCalculatedTaxSpouseA();
      this.getCalculatedTaxSpouseB();
    },
    async showHelp(e: Event, text: string) {
      e.stopPropagation();
      e.preventDefault();
      const popover = await popoverController.create({
        component: ContextualHelp,
        componentProps: { text },
        event: e,
        translucent: true,
      });
      await popover.present();
      await popover.onDidDismiss();
    },
  },
  mounted() {
    this.getLookUpTable();
  },
  watch: {
    show_self_employment_income_spouse_a() {
      this.spouse_a_self_employment_trading_income = 0;
    },
    show_self_employment_income_spouse_b() {
      this.spouse_b_self_employment_trading_income = 0;
    },
    show_pension_income_spouse_a() {
      this.spouse_a_pension_income = 0;
    },
    show_pension_income_spouse_b() {
      this.spouse_b_pension_income = 0;
    },
    show_other_income_spouse_a() {
      this.spouse_a_other_income = 0;
    },
    show_other_income_spouse_b() {
      this.spouse_b_other_income = 0;
    },
    showIndepenentWidget() {
      if (this.showIndepenentWidget) {
        setTimeout(() => {
          const coord =
            (
              this.$refs.independentWidget as HTMLElement
            ).getBoundingClientRect().top + window.scrollY; //(1)
          window.scroll({ top: coord, behavior: "smooth" });
        }, 100);
      }
      this.calculateAll();
    },
    lookupTable() {
      this.calculateAll();
    },
    spouse_a_salary() {
      this.calculateAll();
    },
    spouse_a_benefits_in_kind() {
      this.calculateAll();
    },
    spouse_a_self_employment_trading_income() {
      this.calculateAll();
    },
    spouse_a_pension_income() {
      this.calculateAll();
    },
    spouse_a_other_income() {
      this.calculateAll();
    },
    spouse_a_work_expenses() {
      this.calculateAll();
    },
    spouse_a_pension_expenses() {
      this.calculateAll();
    },
    spouse_b_salary() {
      this.calculateAll();
    },
    spouse_b_benefits_in_kind() {
      this.calculateAll();
    },
    spouse_b_self_employment_trading_income() {
      this.calculateAll();
    },
    spouse_b_pension_income() {
      this.calculateAll();
    },
    spouse_b_other_income() {
      this.calculateAll();
    },
    spouse_b_work_expenses() {
      this.calculateAll();
    },
    spouse_b_pension_expenses() {
      this.calculateAll();
    },
    dependent_children() {
      this.adjustedChildAllowance = -1;
      this.adjustedAdditionalAllowance = -1;
      this.calculateAll();
    },
    child_care_paid() {
      this.calculateAll();
    },
    mortgage_balance() {
      this.calculateAll();
    },
    mortgage_interest() {
      this.calculateAll();
    },
    fromDate() {
      // If fromDate is not January 1st, set interval to annual
      if (this.fromDate !== this.fromDateMin) {
        this.interval = "annual";
      }
      this.calculateAll();
    },
    toDate() {
      // If toDate is not December 31st, set interval to annual
      if (this.toDate !== this.toDateMax) {
        this.interval = "annual";
      }
      this.calculateAll();
    },
  },
};
